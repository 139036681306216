import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["productOrderSelect"]

  connect() {
    console.log("Connected to vendor controller")
  }

  change(event) {
    let vendorId = event.target.selectedOptions[0].value;
    // let target = this.productOrderSelect.id;
    let target = "product_order_id";

    get(`/vendors/orders_for_vendor?target=${target}&vendor_id=${vendorId}`, {
      responseKind: "turbo-stream"
    })
  }
}
