import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"];

  generate(event) {
    console.log(event.currentTarget.selectedOptions[0].value)
    this.formTarget.requestSubmit();
    // console.log(this.dropdownTarget.value)
    // this.formTarget.requestSubmit();
  }
}
